@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');

$breakpoint-desktop: 900px;

.skip-link {
  display: inline-block;
  padding: 5px 15px;
  z-index: 2;
  position: absolute;
  top: 0;
  left: -10000px;
}

.skip-link:focus {
  left: 0;
}

.grid {
  display: grid;
  grid-template-columns: 100%;
}

.child {
  flex: 1 0 100%;
  height: 60px;
}

.topics {
  grid-row: 2;
}

.sign-in-button {
  border-radius: 30px;
  width: fit-content;
  border-color: #3b2215;
  background-color: #3b2215;
  padding: 15px;
  font-size: large;
  font-weight: bold;
  margin-top: 15px;
}

.ssomsg {
  padding-top: 100px;
}



@media (min-width: $breakpoint-desktop) {
  .grid {
    display: grid;
    grid-template-columns: 5% 95%;
    grid-template-rows: auto;
    grid-template-areas:
            "navBar titleBar"
            "navBar content"
  }

  .topics {
    grid-area: content;
    grid-row: auto;
  }

  .ssomsg {
    padding-top: 10px;
  }

}



