$breakpoint-desktop: 900px;
$vader-breakpoint: 1700px;

.topics-container {
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas: 'controls'
                          'topics';
}

.topics-column {
  grid-area: topics;
}

.card-topics {
  width: 100%;
  padding: 1em;
}

.hidden-in-smallness {
  display: none;
}

.topics-container {
  display: grid;
}

.header-card {
  background: #d9aa52;
  color: #000 !important;
  font-size: xx-large;
  text-shadow: black;
  font-family: 'PT Sans', serif;
  font-weight: bolder;
  border-radius: 10px;
  margin-top: 10px;
  padding: 10px;

  display: flex;
  align-items: center;
  text-align: center;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid black;
    speak: never;
  }

  &:not(:empty)::before {
    margin-right: 0.5em;
  }

  &:not(:empty)::after {
    margin-left: 0.5em;
  }
}

.add-topic-textbox {
  width: 100%;
  border-radius: 10px;
  border-width: 2px;
  margin-top: 10px;
  padding: 10px 10px 10px 40px;
  border-color: black;
  border-style: solid;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.5);
}

.card-spacing {
  margin: 1.5em 0 3em 0;
}

.fade-in:last-child {
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.topics {
  text-align: center;

  .card {
    border-width: 1px;
    border-color: black;
    width: 100%;
    text-align: left;
    padding: 1em;
    border-radius: 10px;
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.5);
  }

  .current-topic {
    .card {
      border-color: #d97f32;
      border-width: 3px;
    }
  }
}

.icon {
  width: 1rem;
  height: 1rem;
}

.primary-button {
  border-color: #d97f32;
  border-style: outset;
  border-radius: 10px;
  border-width: 5px;
  background-color: black;
  color: white;
  width: 4rem;
  height: 4rem;
  margin: 2em;
}

.coffee-button {
  background: none;
  border: none;
  display: grid;
  place-items: center;
  align-self: center;
  padding-bottom: 0.5em;
  color: black;

  img {
    margin-right: 0.2rem;
  }
}

.topic-title {
  padding-bottom: 1em;
}

.line-with-text {
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 1em;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid gray;
    speak: never;
  }

  &:not(:empty)::before {
    margin-right: 0.5em;
  }

  &:not(:empty)::after {
    margin-left: 0.5em;
  }
}

@media (min-width: $breakpoint-desktop) {
  .card-topics {
    width: auto;
    padding: 2em 5em 2em 7em;
  }

  .topics-container {
    grid-template-columns: minmax(auto, 500px) fit-content(100%);
    grid-template-rows: auto minmax(0, 1fr);
    grid-gap: 5rem;
    grid-template-areas: 'topics timer'
                          'topics controls';
  }

  .timer-area {
    grid-area: timer;
  }

  .topic-controls-column {
    grid-area: controls;
  }

  .topics-column {
    display: flex;
    justify-content: space-between;
    max-width: 500px;

    .flex-topics {
      width: 100%;
    }
  }
}

.dark-mode {
  .line-with-text {
    color: white;

    &::before,
    &::after {
      border-bottom-color: white;
    }
  }

  .header-card {
    background-color: #680668;
    color: white !important;

    &::before,
    &::after {
      border-bottom-color: white;
    }
  }

  .add-topic-textbox {
    background-color: #4c4c4c;
    border-color: white;
    box-shadow: 1px 1px 2px 0 rgba(255, 255, 255, 0.5);
    color: white;

    &::placeholder {
      color: white;
    }
  }

  .current-topic {
    .card {
      border-color: #680668;
      border-width: 3px;
    }
  }

  .coffee-button {
    color: white;
  }

  .disabledCard{
    .coffee-button{
      color: #606060;
    }
  }

  .coffee-button:disabled {
    color: gray;
  }

  @media (min-width: $vader-breakpoint) {
    .hidden-in-smallness {
      display: block;
      float: right;
      width: 20%;
      margin-right: 15%;
      margin-top: 150px;
    }
  }
}
