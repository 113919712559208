.notFoundImage {
  text-align: center;
}

.button404 {
  height: 100px;
  font-size: xx-large;
  margin-bottom: 10%;
}

.horseImage {
  margin-bottom: 50px;
  height: 40vh;
}