$breakpoint-desktop: 900px;

@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

.flexForm {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-wrap: wrap;

  background: url("../../assets/Caffieneighted-Mustang-Artisan.png") repeat;

  -webkit-background-size: 500px auto;
  -moz-background-size: 500px auto;
  -ms-background-size: 500px auto;
  -o-background-size: 500px auto;
  background-size: 500px auto;
}

.title {
  font-family: 'Dancing Script';
  font-size: 3em;
  color: black;
  text-shadow: black;
  font-weight: bolder;
  height: 100px;
  margin-top: 40px;
}

.child {
  flex: 1 0 100%;
  height: 60px;
}

.box {
  height: 32rem;
  width: 90%;
  text-align: center;
  $shadow-color: #d97f32;
  @include box-shadow(0 0 30px 10px #3b2215);
  //@include box-shadow(0 0 10px 10px rgba($shadow-color, .5));
  border-radius: 10px;
  background-color: white;
}

.sign-in-button {
  border-radius: 30px;
  width: fit-content;
  border-color: #3b2215;
  background-color: #3b2215;
  padding: 15px;
  font-size: large;
  font-weight: bold;
  margin-top: 15px;
}

.center-align-column{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.create-button {
  height: 60px;
  width: 85%;
  font-size: xx-large;
  margin: 20px auto auto;
}

.create-input {
  text-align: center;
  font-size: large;
  width: 85%;
  margin: 1rem 1rem 1.5rem;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

input:focus::placeholder {
  color: transparent;
}

.login-header {
  margin-bottom: 50px;
}

p {
  font-weight: bold;
}

@media (min-width: $breakpoint-desktop) {
  .box {
    width: 32rem;
  }

  .title {
    font-size: 4em;
  }
}

.tag-line {
  margin: 0 55px;
  display: inline-block;
  background: hsla(0, 0%, 100%, .45);
  padding: 5px;
  border-radius: 10px;
  font-size: 20px;
  line-height: 28px;
}
.tag-line-top-margin{
  margin: 30px 55px
}