$breakpoint-desktop: 900px;


.sun {
  filter: invert(1);
}

.sun .moon {
  width: 40px;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

.bm-burger-bars {
  background: black;
}

.bm-cross {
  background: black;
}

.bm-menu-wrap {
  position: fixed;
  height: 100%;
  width: 100%;
}

.bm-menu {
  background: linear-gradient(rgb(210, 171, 96), rgb(205, 132, 67)); //gradient
  padding-top: 2.5em;
  font-size: 1.5em;
}

.bm-item-list {
  color: #b8b7ad;
  padding: 1.45em 0;
}

span.bm-item, a.bm-item {
  display: inline-block;
  color: black;// TEXT COLOR
  text-decoration: none;
  padding: 0.5em;
  border-top: 1px solid black;
}

.bm-button-border{
  border-bottom: 1px solid black;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.navBar {
  background: linear-gradient(rgb(210, 171, 96), rgb(205, 132, 67));
  width: 100vw;
  max-height: 100%;
}

#homeButton {
  display: none;
}

.info-header {
  display: block;
  border-bottom: none;
}

.close {
  border: none;
  color: black;
  padding-block: initial;
  background: none;
  margin-bottom: auto;
  position: absolute;
  top: 10px;
  right: 0.5em;
  font-size: 2.5rem;
}

.mobile-menu-item{
  display: none;
}

.navBar {
  .navItem {
    cursor: pointer;
    padding: 0;

    a {
      padding: 0;
    }

    img {
      height: 64px;
      width: 64px;
    }
  }

  .menu-item {
    margin-top: 10px;
    margin-bottom: 10px;
    transition: all .2s ease-in-out;

    &:hover {
      transform: scale(1.25);
    }
  }

  .link-copy, .dark-mode-switcher {
    width: 64px;
    background-color: transparent;
    color: black;
    font-weight: bold;
    text-decoration: none;

    &:hover {
      transform: scale(1.25);
    }
  }

  .link-copy, .dark-mode-switcher {
    transition: transform 330ms ease-in-out;
  }

  .link-copied {
    color: black;
    height: 64px;
    animation: fadeinout 5s linear 1 forwards;
  }

  @keyframes fadeinout {
    0% {
      transform: scale(0);
    }
    10% {
      transform: scale(1);
    }
    90% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
}

.navContainer {
  display: flex;
  flex-wrap: nowrap;
  padding: 1rem;
  margin-bottom: 0;
  list-style: none;
  align-items: center;
  min-width: 2rem;
  width: 100%;
  position: fixed;
  z-index: 1;
}

.dark-mode-button {
  background-color: black;
  border: none;
  border-radius: 10px;
  color: white;
  padding: 2px 1em;
  margin-top: 10px;
  font-size: large;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.5);
  transition: all .2s ease-in-out;

  &:hover {
    transform: scale(1.1);
    background-color: #3b2215;
  }

  &:focus {
    background-color: #1f0f07;
    box-shadow: none;
  }
}

.modal-window {
  min-width: 60%;
}

.info-modal {
  border-radius: 10px;
  padding: 5%;
  background-color: rgb(210, 171, 96)
}

.modal-title {
  text-align: center;
  padding-bottom: 16px;
  font-size: 40px;
  font-weight: bold;
}

.modal-content {
  border: rgba(0, 0, 0, 0);
  border-radius: 10px;
}

.modal-dialogue-tips {
  text-align: left;

  ul {
    padding: 16px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.45);
    font-weight: bold;
    //list-style-image: url("../../assets/coffee-bean.svg");

    li {
      list-style: none;
      padding-left: 16px;
      margin-bottom: 8px;
    }

    li::before {
      height: 10px;
      width: 10px;
      padding-right: 16px;
      margin-left: -16px;
      background-image: url("../../assets/coffee-bean.svg");
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      content: '';
    }
  }
}

.modal-dialogue-description {
  text-align: center;

  p {
    padding: 16px;
    border-radius: 10px;
  }
}

.info-modal-h3 {
  padding-top: 32px;
  padding-bottom: 8px;
  text-align: center;
}

@media (min-width: $breakpoint-desktop) {
  .bm-burger-button{
    display: none;
  }

  .mobile-menu-item{
    display: block;
  }

  .navBar {
    width: auto;
    grid-row: span 2;
    height: 100%;
  }

  .navContainer {
    width: initial;
  }

  #homeButton {
    display: block;
  }
}

.dark-mode {
  .dark-mode-button {
    background-color: white;
    color: #290129;

    &:hover {
      background-color: #ffdbff;
    }

    &:focus {
      background-color: #f9cbf9;
      box-shadow: none;
    }
  }

  .navBar {
    background: linear-gradient(#310231, #480348);
  }

  .bm-menu {
    background: linear-gradient(#310231, #480348);//gradient
  }

  .bm-cross {
    background: white;
  }

  .bm-burger-bars {
    background: white;
  }

  span.bm-item, a.bm-item {
    display: inline-block;
    color: white;// TEXT COLOR
    text-decoration: none;
    padding: 0.5em;
    border-top: 1px solid white;
  }

  .bm-button-border{
    border-bottom: 1px solid white;
  }

  .menu-item, #logo {
    filter: invert(100%);
  }

  .link-copy {
    color: white;
  }

  .link-copied {
    color: white;
    background-color: #5a0579;
  }

  .info-modal-h3 {
    background: none;
    color: white;
  }

  .info-modal {
    background-color: #704770;
  }

  .close {
    color: white;
  }

  .modal-title {
    color: white;
  }

  .modal-dialogue-tips {
    color: white;

    ul {
      background-color: #310231;

      li::before {
        background-image: url("../../assets/white-coffee-bean.svg");
      }
    }
  }

  .modal-dialogue-description {
    color: white;

    p {
      background-color: #310231;
    }
  }
}









