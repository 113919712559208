body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.App {
    height: 100%;
    min-height: 100vh;
}

.button {
    border-color: #d97f32;
    border-style: solid;
    border-width: 3px;
    background-color: black;
    border-radius: 10px;
    color: white;
    padding: 2px 1em;
    font-size: larger;
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.5);

    &:disabled {
        background-color: gray;
        color: #ffffff;
        border-color: #c0c0c0;
        opacity: .4;

    }
}

.dark-mode {
    .App {
        background-color: #121212;
    }

    .button, .sort-button {
        border-color: #480348;
        box-shadow: 1px 1px 2px 0px rgba(137, 61, 254, 0.5);
        border-width: 3px;
    }

    .footer {
        background-color: #121212;

        .copyright {
            background-color: #121212;
            color: white;
        }

        .copyright a {
            color: #5fa0ff
        }
    }

    h2 {
        color: white;
    }
    .topicLabel{
        background: #480348;
        text-align: center;
        color: white;
    }

    .dropdown-toggle::after {
        display: none;
    }
    .show>.btn-primary.dropdown-toggle, .btn-primary:hover {
        background-color: #4c4c4c;
        color: white;
    }
    .show>.btn-primary.dropdown-toggle:focus{
        box-shadow: none;
    }
    .dropdown-menu-button {
        color:white;
    }
    .checkbox-button {
        color:white;
    }
}



