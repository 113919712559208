$breakpoint-desktop: 900px;

.footer {
  text-align: center;
}

p {
  margin: 0 auto;
  display: inline-block;
  background: rgba(255, 255, 255, 0.45);
  padding: 5px;
  border-radius: 10px;
}

@media (min-width: $breakpoint-desktop) {
  .footer {
    margin-top: -40px;
  }
}