$coffeeColor: #3b2215;
$lightCoffeeColor: #c6a665;
$breakpoint-desktop: 900px;

.timer-buttons {
  min-height: 50px;
  width: 100%;
  margin: 1rem 0;
}

.cup-container {
  display: inline-block;
}


@keyframes move-down {
  0% {
    // transform: translateY(-70%);

  }
  100% {
    // transform: translateY(0%);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.alarm {
  animation-name: shake;
  animation-iteration-count: 7;
  animation-duration: 0.7s;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(20px);
  }

  50% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0px);
  }
}

.cup-image, .space, .coffee, .timer-face, .steam {
  display: none;
}

.timer-face {
  font-family: "PT Sans", serif
}

.reset-timer-select__placeholder {
  font-size: medium;
}

div.reset-timer-select__control {
  min-height: 58px;
}

.timer-mobile {
  position: relative;
  font-size: xx-large;
  font-weight: bolder;
  display: block;
  z-index: 0;
  order: 1;
}

.startStop {
  order: 2;
}

.setTimer {
  order: 3;
}

.timer-button-container {
  margin-top: -2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 100px;

  flex-direction: row;
  align-content: center;
  justify-content: space-evenly;
  overflow-wrap: normal;

  & > * {
    flex: 0 0 45%;
    margin: 10px 0;
    width: auto;
  }
}

.timerPadding {
  margin: 1rem 0;
  padding: 2px 1em;
}

.dark-mode {
  .timer-mobile {
    color: white;
  }

  div.reset-timer-select__control {
    box-shadow: 1px 1px 2px 0 rgb(137 61 254 / 50%)
  }
}

@media (min-width: $breakpoint-desktop) {
  .reset-timer-select__placeholder {
    font-size: 19.2px;
  }

  div.reset-timer-select__control {
    min-height: 50px;
  }

  .cup-image {
    position: relative;
    display: inline-block;
    height: 15rem;
    z-index: 20;
  }

  .coffee {
    position: relative;
    background: $coffeeColor;
    border: 0.25rem solid $coffeeColor;
    height: 15rem;
    width: 15rem;
    outline: 0;
    z-index: -1;
    margin-top: -100%;
    margin-bottom: 3%;

    display: flex;
    align-items: center;
    justify-content: center;

    .space {
      position: absolute;
      display: block;
      content: '';
      background-color: white;
      height: 18rem;
      width: 18rem;
      border-radius: 45%;
      animation: spin 2s linear infinite;
      margin-right: 20px;
    }

    .higher-space {
      display: block;
      content: '';
      height: 24rem;
      width: 24rem;
    }
  }

  .timer-face {
    display: block;
    position: relative;
    font-size: xx-large;
    font-weight: bolder;
    margin-top: -57%;
    z-index: 999;
    width: 50%;
    margin-left: 20%;
    background-color: rgba(255,255,255,.7);
    border-radius: 20px;
  }

  .timer-button-container {
    flex-direction: column;
    justify-content: center;
    margin-top: 6rem;
    align-items: normal;
    padding-top: initial;

    & > * {
      width: 100%;
    }
  }

  .timer-mobile {
    display: none;
  }

  //steam
  .steam {
    display: block;
    position: absolute;
    height: 200px;
    width: 200px;
    border-radius: 50%;
    background-color: #d4d4d4;
    margin-top: -90px;
    margin-left: 20px;
    z-index: 5;
    opacity: 0;
  }

  #steam1 {
    -webkit-animation: steam1 6s ease-out 8;
    animation: steam1 6s ease-out 8;
  }

  #steam3 {
    -webkit-animation: steam1 6s ease-out 3s 8;
    animation: steam1 6s ease-out 3s 8;
  }

  @-webkit-keyframes steam1 {
    0% {
      transform: translateY(0) translateX(0) scale(0.10);
      opacity: 0.4;
    }
    100% {
      transform: translateY(-200px) translateX(-20px) scale(1);
      opacity: 0;
    }
  }

  @keyframes steam1 {
    0% {
      transform: translateY(0) translateX(0) scale(0.10);
      opacity: 0.4;
    }
    100% {
      transform: translateY(-200px) translateX(-20px) scale(1);
      opacity: 0;
    }
  }

  #steam2 {
    -webkit-animation: steam2 6s ease-out 0.5s 8;
    animation: steam2 6s ease-out 0.5s 8;
  }

  #steam4 {
    -webkit-animation: steam2 6s ease-out 1.5s 8;
    animation: steam2 6s ease-out 1.5s 8;
  }

  #steam5 {
    -webkit-animation: steam1 6s ease-out 3s 8;
    animation: steam1 6s ease-out 3s 8;
  }

  #steam6 {
    -webkit-animation: steam1 6s ease-out 4s 8;
    animation: steam1 6s ease-out 4s 8;
  }

  #steam7 {
    -webkit-animation: steam1 6s ease-out 4.5s 8;
    animation: steam1 6s ease-out 4.5s 8;
  }

  #steam8 {
    -webkit-animation: steam1 6s ease-out 5s 8;
    animation: steam1 6s ease-out 5s 8;
  }

  @-webkit-keyframes steam2 {
    0% {
      transform: translateY(0) translateX(0) scale(0.10);
      opacity: 0.4;
    }
    100% {
      transform: translateY(-200px) translateX(20px) scale(1);
      opacity: 0;
    }
  }

  @keyframes steam2 {
    0% {
      transform: translateY(0) translateX(0) scale(0.10);
      opacity: 0.4;
    }
    100% {
      transform: translateY(-200px) translateX(20px) scale(1);
      opacity: 0;
    }
  }

  @-webkit-keyframes steam3 {
    0% {
      transform: translateY(0) translateX(0) scale(0.10);
      opacity: 0.4;
    }
    100% {
      transform: translateY(-200px) translateX(50px) scale(1);
      opacity: 0;
    }
  }

  @keyframes steam3 {
    0% {
      transform: translateY(0) translateX(0) scale(0.10);
      opacity: 0.4;
    }
    100% {
      transform: translateY(-200px) translateX(50px) scale(1);
      opacity: 0;
    }
  }

  @-webkit-keyframes steam4 {
    0% {
      transform: translateY(0) translateX(0) scale(0.10);
      opacity: 0.4;
    }
    100% {
      transform: translateY(-200px) translateX(-50px) scale(1);
      opacity: 0;
    }
  }

  @keyframes steam4 {
    0% {
      transform: translateY(0) translateX(0) scale(0.10);
      opacity: 0.4;
    }
    100% {
      transform: translateY(-200px) translateX(-50px) scale(1);
      opacity: 0;
    }
  }

  @-webkit-keyframes steam5 {
    0% {
      transform: translateY(0) translateX(0) scale(0.15);
      opacity: 0.4;
    }
    100% {
      transform: translateY(-200px) translateX(30px) scale(5.25);
      opacity: 0;
    }
  }

  @keyframes steam5 {
    0% {
      transform: translateY(0) translateX(0) scale(0.15);
      opacity: 0.4;
    }
    100% {
      transform: translateY(-200px) translateX(30px) scale(5.25);
      opacity: 0;
    }
  }

  @-webkit-keyframes steam6 {
    0% {
      transform: translateY(0) translateX(0) scale(0.25);
      opacity: 0.4;
    }
    100% {
      transform: translateY(-200px) translateX(30px) scale(5.25);
      opacity: 0;
    }
  }

  @keyframes steam6 {
    0% {
      transform: translateY(0) translateX(0) scale(0.25);
      opacity: 0.4;
    }
    100% {
      transform: translateY(-200px) translateX(30px) scale(5.25);
      opacity: 0;
    }
  }

  @-webkit-keyframes steam7 {
    0% {
      transform: translateY(0) translateX(0) scale(0.25);
      opacity: 0.3;
    }
    100% {
      transform: translateY(-200px) translateX(-30px) scale(3.25);
      opacity: 0;
    }
  }

  @keyframes steam7 {
    0% {
      transform: translateY(0) translateX(0) scale(0.25);
      opacity: 0.3;
    }
    100% {
      transform: translateY(-200px) translateX(-30px) scale(3.25);
      opacity: 0;
    }
  }

  @-webkit-keyframes steam8 {
    0% {
      transform: translateY(0) translateX(0) scale(0.25);
      opacity: 0.4;
    }
    100% {
      transform: translateY(-200px) translateX(15px) scale(3.25);
      opacity: 0;
    }
  }

  @keyframes steam8 {
    0% {
      transform: translateY(0) translateX(0) scale(0.25);
      opacity: 0.4;
    }
    100% {
      transform: translateY(-200px) translateX(15px) scale(3.25);
      opacity: 0;
    }
  }

  //soo much steam, but its over now

  .dark-mode {
    .coffee {
      background: $lightCoffeeColor;
      z-index: 1;
    }

    .space {
      background-color: #121212;
      z-index: 2;
    }

    .timer-face {
      color: white;
      background-color: rgba(0,0,0,0.7);
    }
  }
}