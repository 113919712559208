.hidden {
  display: none;
}

.topicTitleSpan {
  margin-bottom: 10px;
  width: 90%;
  padding-left: 1em;
}

.topicInput {
  width: 90%;
  border-radius: 10px;
  border-width: 2px;
  margin-top: 10px;
  border-color: black;
  border-style: solid;
  padding: 5px;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.5);
}

.topicInput:focus {
  border-color: #d97f32;
  outline: none;
}

.topic-actions-menu {
  text-align: right;
  font-weight: bold;
  align-self: start;
  margin-top: -1em;
}

.mark-complete {
  text-align: right;
  align-self: end;
}

.edit-mode-buttons {
  padding-top: 10px;
  text-align: center;
}

.dropdown-menu-button {
  background: none;
  color: black;
  border: none;
  padding: 2px;
}

.dropdown-toggle::after {
  display: none;
}
.show>.btn-primary.dropdown-toggle, .btn-primary.dropdown-toggle:hover {
  background-color: white;
  color: black;
}
.btn-primary.dropdown-toggle:focus{
  box-shadow: none;
}

.cardTopicGrid {
  display: grid;
  grid-template-columns: 5% 85% 10%;
}

.topicControlGrid {
  display: grid;
}

.editButton {
  width: 100%;
  background: none;
  border: none;
  padding-top: 0.25rem;
  padding-bottom: 0.5rem;
}

.topicMenu {
  background: white;
  border-radius: 10%;
  border: 1px solid black;
  position: absolute;
  top: -0.75rem;
  right: -0.75rem;
  width: 50px;
}

.disabledCard {
  background-color: #DDD;
}

.topicMenuDisabled {
  background: #DDD;

}

.activeCard {
  background-color: white;
}

.edit-mode-shadow {
  background: #eaeaea;
}

.disabledText {
  color: #404040;
  text-decoration: line-through;
}

.cancel-button {
  background-color: #887f72;
  border-radius: 5%;
  color: white;
  font-weight: bold;
  border: none;
  box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.5);
}

.save-button {
  background-color: #d9aa52;
  border-radius: 5%;
  color: white;
  font-weight: bold;
  border: none;
  box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.5);
  margin-right: 5%;
}

.dropdown-menu-button:focus {
  box-shadow: none;
  border: none;
  background: none;
  color: black;
}

.dark-mode {

  .topicInput {
    background-color: #262626;
    border-color: white;
    box-shadow: 1px 1px 2px 0 rgba(255, 255, 255, 0.5);
    color: white;

    &::placeholder {
      color: white;
    }
  }
  .topicInput:focus {
    border-color: #5a0579;
    outline: none;
    box-shadow: 0px 0px 0px 0;
  }

  button:disabled {
    svg {
      fill: #746e6e;
    }
  }

  .activeCard {
    background-color: #4c4c4c;
    color: white;

    .topicMenu {
      background-color: #4c4c4c;

      svg {
        fill: white;
      }
    }
  }
  .save-button {
    background-color: #5a0579;
  }
  .disabledCard {
    svg {
      fill: black;
    }
  }
  .btn-primary:focus {
    box-shadow: none;
    border-color: #4c4c4c;
    background-color: #4c4c4c;
    color: white;
  }
}
