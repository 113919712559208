$breakpoint-desktop: 900px;

.votingForm {
  height: 100%;
}

.votingContainer {
  display: flex;
  justify-content: space-evenly;
  height: 100%;
}

.votingChild {
  padding: 5px;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.topicLabel {
  text-align: center;
  background: #d9aa52;
}

.topicText {
  background: white;
  padding: 10px;
  border: 3px solid black;
  margin: 0 auto;
  height: 150px;
  width: 100%;
  overflow-y: auto;
}

.votingResultText {
  background: white;
  padding: 10px;
  border: 3px solid black;
  border-radius: 10px;
  margin: 0 auto;
  width: 30%;
  text-align: center;
  align-items: center;
}

.darkVotingResultText {
  background: black;
  color: white;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 1000;
}

.voteButton {
  background: black;
  color: white;
  border-radius: 10px;
  border: none;
  padding: 10px;
  font-weight: bold;
  width: 100px;
  margin: 10px auto;

  &:focus {
    background: #d98032;
    color: black;
  }

  &:hover {
    background: #d98032;
    color: black;
  }

  &:active {
    background: #d98032;
    color: black;
  }
}

.modalTitle {
  text-align: center;
}

.endVotingButton {
  background: black;
  color: white;
  border-radius: 10px;
  border: none;
  padding: 10px;
  font-weight: bold;
  width: 40%;
  max-width: 250px;
  margin: 10px auto;
  display: block;
}

.votingModal {
  padding: 10px;
  background: #d9aa52;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 1000;
  overflow-y: hidden;

  &:focus {
    outline: none;
  }
}

.darkModal {
  background: #480348;
}

@media (min-width: $breakpoint-desktop) {
  .votingModal {
    width: 50%;
  }
}
