$breakpoint-desktop: 900px;

.sort-button {
  display: none;
}

.voteRemainingText {
  margin: 10px;
  position: fixed;
  top: 0;
  z-index: 1;
  right: 0;
  width: min-content;
  color: black;
}

.qr-code {
  display: none;
}

.dark-mode {
  .voteRemainingText {
    color: white;
  }
}

@media (min-width: $breakpoint-desktop) {
  .sort-button {
    border-color: #d97f32;
    border-style: solid;
    border-radius: 10px;
    border-width: 3px;
    background-color: black;
    width: 100%;
    height: 50px;
    color: white;
    padding: 2px 1em;
    font-size: larger;
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.5);
    margin-top: -1px;
  }

  .sort-button {
    float: left;
    margin: 1em 0 1em 0;
    display: block;
    margin-top: -1px;
  }

  .sort-button:disabled {
    background-color: #DDD;
    border: none;
    box-shadow: none;
    color: #454545;
    cursor: not-allowed;
    margin-top: -1px;
  }

  .qr-code {
    display: initial;
    margin-top: 1em;
  }

  .voteRemainingText {
    position: initial;
    top: initial;
    z-index: initial;
    right: initial;
    width: initial;
    margin: 10px 0;
  }
}